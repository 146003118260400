<template>
    <div>
        <div class="full-width category-container m-t-36">
            <div class="tac container neue neueSettings">
                <Breadcrumbs :path="path" />
            </div>
        </div>
        <div class="full-width p10">
            <div class="tac container flexColumn">
                <h3 class="neue title neueSettings">Meridiano 361</h3>
                <p class="neue neueSettings"><b>Abbigliamento</b></p>

                <center class="only-mobi">
                  <img class="quidLogo" :src="quidLogo.url" alt="" srcset="" style="margin-bottom: 0">
                </center>
            </div>
        </div>
        <div class="full-width m-t-36 p10">
            <div id="infoTable" class="container neueSettings">
                <div class="container70">
                    <div class="row ">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Paese</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Italia</p>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Prodotti</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">All’interno della Cooperative Collection di On Earth, Meridiano 361 propone <b>bigiotteria etica</b> realizzata con diversi materiali quali pietre semipreziose, ottone, perline di vetro, ma anche corno e legno riciclati o provenienti dagli scarti delle produzioni industriali.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Valore protetto / impegno</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Giustizia sociale ed economica, equa retribuzione del lavoro, sviluppo sostenibile e rispetto per le persone e per l’ambiente.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Con che paesi lavora</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Per il brand On Earth, Meridiano 361 collabora con gruppi di artigiani di <b>India</b> ed <b>Ecuador</b>, con i quali cura la creazione di collezioni di bigiotteria.</p>
                        </div>
                    </div>
                </div>
                <div class="container20">
                    <img class="quidLogo no-mobi" :src="quidLogo.url" alt="" srcset="">
                    <img class="miniMap" :src="miniMap.url" alt="" srcset="">
                    <a href="https://www.meridiano361.it" target="_blank">www.meridiano361.it</a>
                </div>
            </div>
        </div>
        <div id="slider" class="mb-6r">
            <VueSlickCarousel v-bind="settings">
                <img src="@/assets/meridiano/1.jpg" alt="">
                <img src="@/assets/meridiano/2.jpg" alt="">
                <img src="@/assets/meridiano/3.jpg" alt="">
                <img src="@/assets/meridiano/6.jpg" alt="">
                <img src="@/assets/meridiano/4.jpg" alt="">
                <img src="@/assets/meridiano/5.jpg" alt="">
            </VueSlickCarousel>
        </div>
        <div class="full-width mb-4r p10">
            <div class="container flexColumn">
                <div class="row flexColumn">
                    <h3 class="neue title neueSettings">Descrizione</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;"><b>Meridiano 361 è un’impresa sociale</b> costituita nel 2019 nel territorio di <b>Reggio Emilia</b> con l’obiettivo di <b>sostenere e promuovere i valori alla base del Commercio Equo e Solidale</b>. Nasce per iniziativa della cooperativa sociale La Vigna, che dal 1985 è impegnata sul fronte dell’inclusione al lavoro di soggetti svantaggiati e, contemporaneamente, nell’assistenza a persone segnate da fragilità. Attraverso la vendita all’ingrosso e al dettaglio, supportate dai rispettivi e-commerce, si propongono prodotti artigianali realizzati nel rispetto dei valori e dei criteri della <b>Carta del Commercio Equo</b>. Importante per Meridiano 361 è riuscire a sottolineare il <b>valore aggiunto</b> che conferisce unicità ad ogni pezzo, rendendolo riconoscibile per il consumatore finale, il quale mediante l’acquisto diventa parte della storia che c’è d<b>dietro a ogni singolo prodotto, fatta innanzitutto di persone</b>.</p>
                </div>   
                <div class="row flexColumn m-t-36">
                    <h3 class="neue title neueSettings">Curiosità</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Come si legge dal sito dell’organizzazione, “il nome Meridiano 361 vuole raffigurare un nuovo meridiano, quello ‘in più’ oltre a quelli già esistenti. I meridiani geografici sono infatti 360, <b>Meridiano 361 rappresenta per noi l’orizzonte nuovo, una visione di mondo che ci spinge oltre i limiti canonici, al di là dei confini tra persone e nazioni</b>. È la visione che il commercio equo propone e di cui esso si rende strumento e mezzo.”</p>
                </div> 
            </div>
        </div>
        <ProductSlider supplier="M361" />
    </div>
</template>
<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ProductSlider from '@/components/ProductSlider'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    const meridianoLogo = require('../assets/meridiano/meridiano.png')

    export default {
        name : 'Meridiano361',

        metaInfo: {
          title: 'Meridiano 361'
        },
        components : {
            Breadcrumbs,
            ProductSlider,
            VueSlickCarousel
        },

        data() {
            return {
                path: [
                    { label: 'Home', path: '/' },
                    { label: 'Brand', path: '/brand' },
                    { label: 'Meridiano 361', path: '/meridiano' }
                ],
                miniMap : {
                    url : 'https://www.altromercato.it/wp-content/uploads/2021/09/Bosnia-ed-Erzegovina.jpg'
                },
                quidLogo : {
                    url : meridianoLogo
                },
                settings : {
                    "arrows": true,
                    "dots": false,
                    "infinite": true,
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "speed": 500,
                    "cssEase": "linear",
                    "responsive": [
                        {
                            "breakpoint": 668,
                            "settings": {
                                "speed": 500,
                                "slidesToShow": 2,
                                "slidesToScroll": 2,
                                "infinite": true,
                                "dots": false
                            }
                        },
                    ]
                }
            }
        }
    }

</script>
<style >
    #slider .slick-next {
        right: 67px;
    }
    #slider .slick-prev {
        left: 67px;
        z-index: 1;
    }
    .mb-4r{
        margin-bottom: 4rem;
    }
    .mb-6r{
        margin-bottom: 6rem;
    }
    .neueSettings{
        letter-spacing: 0.5px;
        line-height: 1.5em;
    }
    .miniMap{
        max-width: 100%;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
        padding: 10px;
        margin-bottom: 2rem;
    }
    .quidLogo{
        max-width: 50%;
        margin-bottom: 2rem;
    }
    .title{
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0;
    }
    .container70{
        max-width: 75%;
    }
    .container20{
        max-width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateY(-120px);
    }
    .neueSetBold{
        font-weight: 600;
    }
    .flexColumn{
        flex-direction: column;
    }
    .col-25{
        width: 30%;
        max-width: 30%;
    }
    .col-50{
        width: 50%;
        max-width: 50%;
    }
    .col-60{
        width: 60%;
        max-width: 60%;    
    }
    .col-75{
        width: 75%;
        max-width: 75%;
    }
    @media(max-width: 668px){
        #infoTable{
            flex-direction: column;
        }
        #infoTable .container70{
            max-width: 100%;
        }
        #infoTable .container20{
            transform: unset;
            max-width: 100%;
        }
        #infoTable .quidLogo{
            max-width: 20%;
            margin-top: 2rem;
        }
        #infoTable a{
            margin-bottom: 4rem;
        }
        .col_sm-100{
            max-width: 100%;
            width: 100%;
        }
        .p10{
            padding: 10px;
        }
        .tac{
            text-align: center;
        }
    }
</style>
